// Colors
$main-background-color: #333333;
$main-text-color: white;

$pink: #ea076d;
$pink-background: #f5d8e5;

$green: #1a9c71;
$green-background: #d5f1e8;

$blue: #405be2;
$blue-background: #d7dbf5;

$orange: #f44336;
$orange-background: #f5d0ce;

$border-radius-strong: 12px;
$box-shadow-strong: 0 1px 1px rgba(16,27,30,.4), 0 2px 2px rgba(16,27,30,.3), 0 4px 4px rgba(16,27,30,.15), 0 8px 8px rgba(16,27,30,.15), 0 16px 16px rgba(16,27,30,.15);

$speed-fast: 300ms;
$ease: cubic-bezier(0.215, 0.61, 0.355, 1);
$transition-fast: all 180ms $ease 0s;
$transition-medium: all 300ms $ease 0s;
$element-position-transition:
    top $speed-fast $ease 0s, 
    bottom $speed-fast $ease 0s,
    left $speed-fast $ease 0s,
    right $speed-fast $ease 0s;

$text-shadow-normal: 0 1px rgba(0, 0, 0, 0.14);
$serif-font: 'Lato', sans-serif;
$monospace: 'Roboto Mono', monospace;